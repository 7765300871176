<template>
  <ContentWrapper>
    <div class="row">
      <div class="col-4 position-static right-border">
        <settlements-card></settlements-card>
      </div>
      <div class="col-8 position-static">
        <time-entries-card
            :namespace="'settled_reports'"
            docxButton
            pdfButton
            unsettleButton
        ></time-entries-card>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import SettlementsCard from '../Report_v2/settlementsCard.vue';
import TimeEntriesCard from '../Report_v2/timeEntriesCard.vue';
import ContentWrapper from '../../components/HOCs/ContentWrapper';

export default {
  name: 'SettlementsIndex',
  components: {
    ContentWrapper,
    SettlementsCard,
    TimeEntriesCard,
  },
};
</script>

<style scoped>

</style>
